<template>
  <div>
    <v-container>
      <v-row justify="center" style="margin-top:130px">          
        <botonVolver style="display: inline;"/>           
        <botonBoleta style="display: inline;" @Click="getPdf" />
        <botonQr style="display: inline;" @Click="openMedioPago" />
    </v-row>
    </v-container>
    <v-container>
      <v-layout column style="height: 30vh">
        <v-flex md6 style="overflow: auto">
          <h3>Deuda</h3>
          <v-data-table
          style="cursor:pointer"
          :headers="headers"
          :items="registros.cuotasVencidas"
          class="elevation-1"
          hide-default-footer
          :items-per-page="itemsPerPage"
          :key="tablaKey"
        >                
          <template v-slot:body="{items}">
            <tbody>
              <tr v-for="(item,k) in items" :key="k" :class="item.esMora && !item.check ? 'colorMora' : item.esMora && item.check ? 'colorMoraCheck' : ''" @click.stop="clickRow(item)" class="pointer">
                <td>
                  <v-checkbox
                    class="shrink mt-0 mb-n6"
                    :ripple="false"
                    color="primary"
                    v-model="item.check"
                    disabled
                  ></v-checkbox>     
                </td>
                <td>{{ item.periodo }}</td>
                <td>$ {{ $formatNumber(item.recargo) }}</td>
                <td>$ {{ $formatNumber(item.capital) }}</td>
                <td>$ {{ $formatNumber(item.multa) }}</td>
                <td>$ {{ $formatNumber(item.total) }}</td>
                <td>{{item.vencimiento}}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>   
          </v-flex>
        <v-flex md6 style="overflow: auto">
          <h3>A vencer</h3>
          <v-data-table
          style="cursor:pointer"
          :headers="headers"
          :items="registros.cuotasAVencer"
          class="elevation-1"
          hide-default-footer
          :items-per-page="itemsPerPage"
          :key="tablaKey"
        >
          <template v-slot:body="{items}">
            <tbody>
              <tr v-for="(item,k) in items" :key="k" :class="item.esMora && !item.check ? 'colorMora' : item.esMora && item.check ? 'colorMoraCheck' : ''" @click.stop="clickRow(item)" class="pointer">
                <td>
                  <v-checkbox
                    class="shrink mt-0 mb-n6"
                    :ripple="false"
                    color="primary"
                    v-model="item.check"
                    disabled
                  ></v-checkbox>     
                </td>
                <td>{{ item.periodo }}</td>
                <td>$ {{ $formatNumber(item.recargo) }}</td>
                <td>$ {{ $formatNumber(item.capital) }}</td>
                <td>$ {{ $formatNumber(item.multa) }}</td>
                <td>$ {{ $formatNumber(item.total) }}</td>
                <td>{{item.vencimiento}}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>  
        </v-flex>
      </v-layout>
    </v-container>
      <pdfDialog v-model="showPdfDialog" ref="pdfDialog" />
      <qrDialog v-model="showQrDialog" ref="qrDialog" />
      <mediosPagoDialog v-model="showMediosPagoDialog"  @setQr="setQr" ref="mediosPagoDialog" />
  </div>
</template>

<script>  
export default {
  components: {
    pdfDialog: () => import('../components/pdfDialog.vue'),
    mediosPagoDialog: () => import('../components/mediosPagoDialog.vue'),
    qrDialog: () => import('../components/qrDialog.vue'),
    botonQr: () => import('../components/botonQr.vue'), 
    botonBoleta: () => import('../components/botonBoleta.vue'),
  }, 
  props: {
    tributo: {
      type: String,
      required: true,
    },
    cuenta: {
      type: String,
      required: true,
    },
  },    
  data () { return {      
    tablaKey: 0,        
    itemsPerPage: -1,
    registros: {             
                 cuotasVencidas:[
                  { check:false, }
                 ],
                 cuotasAVencer:[
                  { check:false, }
                 ]
                },
    url_pdf: '',
    url_qr: '',
    showPdfDialog: false,
    showMediosPagoDialog: false,
    showQrDialog: false, 
    headers: [  { text: 'Cuota', align: 'start'},  
                { text: 'Período', align: 'start', value: 'periodo' },
                { text: 'Capital', align: 'start', value: 'capital' },
                { text: 'Recargo', align: 'start', value: 'recargo' },
                { text: 'Multa', align: 'start', value: 'multa' },
                { text: 'Total', align: 'start', value: 'total' },
                { text: 'Vencimento', align: 'start', value: 'vencimiento' },  
              ],    
    medioPago:'',
    selected: []
  }},
  mounted: async function () {
    await this.$store.dispatch('loadFecha')
    await this.getCuotas()
    },    
  methods: {
    forceRerender() {
      this.tablaKey += 1;
    },      
    getCuotas: async function () {
      var url = '';
      switch (this.tributo) {
        case 'ALCVP Y SV':
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta + '/ABL'
            break; 
        case 'RODADOS':            
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta+ '/RODADOS'
            break; 
        case 'COMERCIOS':
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta + '/COMERCIOS'
            break; 
        case 'PILETAS':
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta + '/PILETAS'
            break; 
        case 'CEMENTERIO':
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta + '/CEMENTERIO'
            break; 
        case 'VEHICULOS':
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta + '/VEHICULOS'
            break; 
      }
      await this.$store.dispatch('axiosMod/getData', { url: url }).then((response) => { 
        this.registros = response
        this.agregarPropiedadCheck()    
      }).catch(() => { this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])
 })        
    },
    getPdf: async function () {
      await this.generarSelected()
       if (!this.hayCheck()) {
         this.$showNoSeleccion()
         return
       }          
      var url = ''
      switch (this.tributo) {
        case 'ALCVP Y SV':        
            url = '/SAT_WS/rest/recibo/' + this.cuenta + '/ABL/totem'
          break;  
        case 'RODADOS':  
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/RODADOS/totem'
          break; 
        case 'VEHICULOS':  
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/VEHICULOS/totem'
          break;    
        case 'COMERCIOS': 
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/COMERCIOS/totem'      
          break; 
        case 'PILETAS':
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/PILETAS/totem'      
          break;            
        case 'CEMENTERIO': 
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/CEMENTERIO/totem'
          break;                              
        default:
          break;
      }
      await this.$store.dispatch('axiosMod/postData', { url: url, dataForm: { fecha: this.$store.getters.getFecha, cuotas: this.selected } }).then((response) => { 
          this.url_pdf = response.url
          this.showPdfDialog = true
          this.$refs.pdfDialog.setForm(this.url_pdf)            
      }).catch(() => {
          this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])
      })     
    },
    openMedioPago: async function () {  
       if (!this.hayCheck()) {
         this.$showNoSeleccion()
         return
       }  
       this.showMediosPagoDialog = true               
    },   
    setQr: async function (medioPago) {
      await this.generarSelected()
       var url = ''
      switch (this.tributo) {
        case 'ALCVP Y SV':
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/ABL/totem'
          break;  
        case 'RODADOS':  
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/RODADOS/totem'
          break; 
        case 'VEHICULOS':  
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/VEHICULOS/totem'
          break;
        case 'COMERCIOS':
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/COMERCIOS/totem'
          break;    
        case 'PILETAS':
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/PILETAS/totem'
          break;                    
        case 'CEMENTERIO': 
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/CEMENTERIO/totem'                  
          break;
        default:          
          break;
      }         
      await this.$store.dispatch('axiosMod/postData', { url: url, dataForm: { medioPago: medioPago, cuotas: this.selected } }).then((response) => { 
          this.showQrDialog = true
          this.$refs.qrDialog.setForm(response.url)   
      }).catch(() => {
          this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])
      })            
    },
    hayCheck: function () {
      return this.selected.length
    },
    clickRow: function (item) {
      if (item.esMora) {
        if (!item.check) {
          this.$showEsMora()
        }          
        this.registros.cuotasVencidas.forEach(function (item) {
          if (item.esMora) {
            item.check = !item.check
          }
        })
      } else {
        item.check = !item.check          
      }
      this.forceRerender()
    },      
    agregarPropiedadCheck: function () {
      this.registros.cuotasVencidas.forEach(function (item) {
          Object.defineProperty(item, 'check', { value: false, enumerable: true, writable:true, configurable: true})
      });
      this.registros.cuotasAVencer.forEach(function (item) {
          Object.defineProperty(item, 'check', { value: false, enumerable: true, writable:true, configurable: true})
      });           
    },
    generarSelected: async function () {
        this.selected = []
        this.registros.cuotasVencidas.forEach(function (item) {
          if (item.check) {
            this.selected.push(item)
          }
      }.bind(this));
      this.registros.cuotasAVencer.forEach(function (item) {
        if (item.check) {
          this.selected.push(item)
        }          
      }.bind(this));
    }
  },
}
</script>

<style scoped>
.colorMora {
  background-color: #FFEFCE
}
.colorMoraCheck {
  background-color: #FFCE70
}
</style>